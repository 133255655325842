import { Injectable } from '@angular/core';
import {
  AlignmentType,
  Footer,
  Header,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  Document,
  VerticalAlign,
  WidthType,
  ShadingType,
} from 'docx';
import * as saveAs from 'file-saver';
import { Subject } from 'rxjs';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';

@Injectable({
  providedIn: 'root',
})
export class WordReportUtils {
  previewData: REPORTPREVIEW;
  soilType: string;
  title = '';
  signature: ISIGNATURE;
  logo: string;
  address: IADDRESS;
  figureImages: any[] = [];
  imageCount = 0;
  forPdfTableDta: any[] = [];
  formattedDate: string;
  private destroyed$ = new Subject<boolean>();
  public commonForWordData(
    previewValue: REPORTPREVIEW,
    soilType: string,
    title: string,
    sign: ISIGNATURE,
    logo: string,
    address: IADDRESS,
    figureImages: any,
    imageCount: number,
    forPdfTableDta: any,
  ) {
    this.previewData = previewValue;
    this.soilType = soilType;
    this.title = title;
    this.signature = sign;
    this.logo = logo;
    this.address = address;
    this.figureImages = figureImages;
    this.imageCount = imageCount;
    this.forPdfTableDta = forPdfTableDta;
  }

  public commonStyle = {
    font: 'Arial',
    size: 22, // Size in half-points (22 means 11pt)
    color: '000000', // Hex color without #
    tableFontsize: 18,
    tableFont: 'Arial',
  };

  public generateWord() {
    this.formattedDate = this.previewData.reportDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short', // Short month format
      day: 'numeric',
    });

    const letterFooter = new Paragraph({
      children: [
        new TextRun({
          text: 'COOL SOLUTIONS FOR UNDERGROUND POWER CABLES',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Serving the electric power industry since 1978',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterHeader = new Paragraph({
      children: [
        new TextRun({
          text: `${this.address.street1} , ${this.address.street2}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `${this.address.city}, ${this.address.state} `,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `Tel: ${this.address.ph}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.address.email,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.address.weburl,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterAddress = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.formattedDate,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: this.previewData.companyName,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.streetAddress,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.cityState,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `Attn: ${this.previewData.contactName}`,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
      ],
      spacing: {
        after: 200,
      },
    });
    const letterHeading = new Paragraph({
      children: [
        new TextRun({
          text: this.previewData.header,
          bold: true,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          color: '#000000',
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.subHeader,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          color: '#000000',
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.CENTER,
    });
    const letterSignature = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Geotherm USA, LLC,',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new ImageRun({
          data: this.signature.signatureImage,
          transformation: {
            width: 120,
            height: 80,
          },
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.signature.label,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
    });
    const pageHeader = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: this.logo,
                      transformation: {
                        width: 350,
                        height: 100,
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 40,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
            new TableCell({
              children: [],
              width: {
                size: 20,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
            new TableCell({
              children: [letterHeader],
              width: {
                size: 40,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: 'nil',
                },
                bottom: {
                  style: 'nil',
                },
                left: {
                  style: 'nil',
                },
                right: {
                  style: 'nil',
                },
              },
            }),
          ],
        }),
      ],
    });
    const doc: any = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'DefaultParagraph',
            name: 'Default Paragraph',
            basedOn: 'Normal',
            run: {
              font: this.commonStyle.font,
              size: this.commonStyle.size,
            },
          },
        ],
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [letterFooter],
                  alignment: 'center',
                }),
              ],
            }),
          },
          properties: {},

          children: [
            pageHeader,
            letterAddress,
            letterHeading,
            this.docMainBody(),
            this.docTable(),
            this.getCommentsDoc(),
            this.getGeothermCommentForWord(),
            this.getRecommendationForWord(),
            letterSignature,
            ...this.putImagesInDoc(),
          ],
        },
      ],
    });

    // Save the document
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${this.title}.docx`);
    });
  }

  putImagesInDoc() {
    const figureImages = [];
    for (let i = 0; i < this.imageCount; i++) {
      const canvas = document.getElementById(`canvas${i}`) as HTMLCanvasElement;
      figureImages.push({
        image: canvas.toDataURL('image/png', 1.0),
      });
    }
    this.figureImages = [...figureImages];
    const imageArray: any[] = [];
    this.figureImages.forEach((images, i) => {
      imageArray.push(
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      pageBreakBefore: true,
                      children: [
                        new ImageRun({
                          data: this.logo,
                          transformation: {
                            width: 160,
                            height: 50,
                          },
                        }),
                      ],
                      alignment: 'left',
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {
                      style: 'nil',
                    },
                    bottom: {
                      style: 'nil',
                    },
                    left: {
                      style: 'nil',
                    },
                    right: {
                      style: 'nil',
                    },
                  },
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          break: 2,
                        }),
                        new TextRun({
                          text: `THERMAL DRYOUT CURVE - FIGURE ${i + 1}`,
                          bold: true,
                          font: this.commonStyle.font,
                          size: this.commonStyle.size,
                        }),
                      ],
                      alignment: 'center',
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {
                      style: 'nil',
                    },
                    bottom: {
                      style: 'nil',
                    },
                    left: {
                      style: 'nil',
                    },
                    right: {
                      style: 'nil',
                    },
                  },
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: images.image,
                          transformation: {
                            width: 600,
                            height: 600,
                          },
                        }),
                      ],
                      alignment: 'center',
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {
                      style: 'nil',
                    },
                    bottom: {
                      style: 'nil',
                    },
                    left: {
                      style: 'nil',
                    },
                    right: {
                      style: 'nil',
                    },
                  },
                }),
              ],
            }),
          ],
        }),
      );
    });
    return imageArray;
  }

  getCommentsDoc() {
    if (this.soilType === SoilType.Precast) {
      return this.getPreacastComment();
    }
    return this.getNativeComment();
  }

  getPreacastComment() {
    const comments = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Client Comments: ',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart1 ? 'Concrete: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart1 ? this.previewData.commentPart1 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart2 ? 'FTB: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart2 ? this.previewData.commentPart2 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart3 ? 'Grout/Slurry: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart3 ? this.previewData.commentPart3 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart4 ? 'Other: ' : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
        }),
        new TextRun({
          text: this.previewData.commentPart4 ? this.previewData.commentPart4 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart5 ? this.previewData.commentPart5 : '',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
    });
    return comments;
  }

  getNativeComment() {
    const comments = new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: 'Client Comments: ',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart1,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: this.previewData.commentPart2,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
      ],
    });
    return comments;
  }

  docMainBody() {
    if (this.soilType === SoilType.Precast) {
      return this.docMainBodyPrecast();
    }
    return this.docMainBodyNativeSoil();
  }

  docMainBodyNativeSoil() {
    const mainBodyNative = new Paragraph({
      children: [
        new TextRun({
          text: this.previewData.mainBodyIntro,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: 'Thermal Resistivity Tests: ',
          bold: true,
          underline: {},
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: this.previewData.mainBody,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: 'Sample ID, Description, Thermal Resistivity, Moisture Content and Density',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
      ],
      alignment: AlignmentType.LEFT,
    });
    return mainBodyNative;
  }

  docMainBodyPrecast() {
    const mainBodyPrecast = new Paragraph({
      children: [
        new TextRun({
          text: this.previewData.mainBody,
          font: this.commonStyle.font,
          size: this.commonStyle.size,
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
        new TextRun({
          text: 'Test Results: (Average of Cylinders)  ',
          font: this.commonStyle.font,
          size: this.commonStyle.size,
          bold: true,
          underline: {},
        }),
        new TextRun({
          text: '',
          break: 2,
        }),
      ],
      alignment: AlignmentType.LEFT,
    });

    return mainBodyPrecast;
  }

  docTable() {
    const tableValue = new Paragraph({
      children: [],
      alignment: 'center',
    });
    if (this.soilType === SoilType.Precast) {
      const precastTable = new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Sample ID',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Date Cast',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Material Type',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Moisture Content (%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry Density (lb/ft3)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Thermal Resistivity (°C-cm/W)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                columnSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Wet',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
            ],
          }),
          ...this.getPrecastRows(),
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
      });
      return precastTable;
    }
    if (this.soilType === SoilType.Native) {
      const nativeTable = new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Sample ID',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Depth (ft)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Effort (%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Description',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Thermal Resistivity (°C-cm/W)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                columnSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Moisture Content (%)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry Density (lb/ft3)',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Dry',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Wet',
                        size: this.commonStyle.tableFontsize,
                        font: this.commonStyle.tableFont,
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  type: ShadingType.CLEAR,
                  fill: 'F9F9F9',
                },
              }),
            ],
          }),
          ...this.getNativeRows(),
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
      });
      return nativeTable;
    }
    return tableValue;
  }

  getNativeRows(): TableRow[] {
    return this.forPdfTableDta.map(
      (item) =>
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.sampleId,
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.depth?.toString() || '',
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.effort?.toString() || '',
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.description || '',
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.wet.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.dry.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.moistureContent.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.density.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
        }),
    );
  }

  getPrecastRows(): TableRow[] {
    return this.forPdfTableDta.map(
      (item) =>
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.sampleId,
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.collectionDate || '',
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.matrialType || '',
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.moistureContent.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.density.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.wet.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.dry.toString(),
                      size: this.commonStyle.tableFontsize,
                      font: this.commonStyle.tableFont,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
        }),
    );
  }

  public stripHtml = (html: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || '';
  };

  getGeothermCommentForWord() {
    const geothermCommentContent = this.previewData.geothermComment;
    const geoComment = new Paragraph({
      children: [],
    });
    if (geothermCommentContent) {
      const plainText = this.stripHtml(geothermCommentContent);
      const value = new Paragraph({
        children: [
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: 'Geotherm Comments: ',
            font: this.commonStyle.font,
            size: this.commonStyle.size,
            bold: true,
            underline: {},
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: plainText,
            font: this.commonStyle.font,
            size: this.commonStyle.size,
          }),
        ],
      });
      return value;
    }
    return geoComment;
  }

  getRecommendationForWord() {
    const recommendationCommentContent = this.previewData.recommendations;
    const recommendationComment = new Paragraph({
      children: [],
    });
    if (recommendationCommentContent) {
      const plainText = this.stripHtml(recommendationCommentContent);
      const value = new Paragraph({
        children: [
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: 'Recommendation: ',
            font: this.commonStyle.font,
            size: this.commonStyle.size,
            bold: true,
            underline: {},
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
          new TextRun({
            text: plainText,
            font: this.commonStyle.font,
            size: this.commonStyle.size,
          }),
        ],
      });
      return value;
    }
    return recommendationComment;
  }

  ngOnDestroy(): void {
    // avoid memory leakage please don't remove the line
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
